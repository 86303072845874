<template>
  <tr id="mycardrow" style="padding: 0px;">
    <td style="text-align: center;padding: 0px;"> {{ myindex + 1 }}</td>

    <td style="padding: 0px;width: 60px;">
      <input ref="el_fabcode" type="number" class="form-control" v-model="row.id" @keyup.enter="loadRoll(row.id)"/>
    </td>
    <td style="padding: 0px;">
      <button class="btn btn-outline-success" @click="loadRoll(row.id)" tabindex="-1">Find</button>
    </td>

    <td style="padding-right: 5px;text-align: right;width: 100px;">
      {{ row.qty | numberFormat }}
    </td>

    <td style="padding-right: 5px;text-align: right;width: 100px;">
      {{ row.weight | weightFormat }}
    </td>

    <td style="padding: 0px;">
      {{ row.quality.name }}
    </td>
    <td style="padding: 0px;">
      {{ row.color.name }}
    </td>


    <td style="padding: 0px;text-align: right">
      <button name="btnRemoveRow" type="button"
              class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow"
              @click="removeRow(index)" tabindex="-1"><i class="icon-eraser"></i></button>
    </td>

  </tr>
</template>

<script>
import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'

export default {
  name: 'BaleDetailRow',
  props: {
    myrow: {
      type: Object,
      default: () => JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":0,"cmp_id":1,"shift":{"id":0,"type":0,"name":""},"doc_date":"2000-01-01","roll_no":0,"emp":{"id":0,"status":0,"emp_code":"","name":""},"process":{"code":350,"name":"","nature":0},"quality":{"id":0,"name":""},"color":{"id":0,"status":0,"name":""},"qty":0,"weight":0,"avg":0,"fabrics":[],"remarks":""}')
    },
    parentvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","bale_no":"","bale_date":"0001-01-01","sale_order":{"id":"","code":0,"status":0,"type":0,"finyear":0,"doc_no":0},"remarks":"","shift":{"id":"","code":0,"type":0,"name":""},"total_pcs":0,"total_mtrs":0,"total_weight":0,"list":[]}'),
    },
    myindex: {
      type: Number,
      default: () => 0
    },
    myprocess: {
      type: Number,
      default: () => 0
    }
  },
  beforeMount () {
    this.row = this.myrow
    this.index = this.myindex
    this.row.idx = this.myindex
  },
  data () {
    return {
      index: 0,
      row: JSON.parse('{"idx":0,"id":0,"status":"Active","finyear":0,"roll_no":0,"doc_date":"2000-01-01","cmp_id":1,"shift_id":1,"mac_id":1,"emp_id":1,"item_id":1,"quality_id":1,"color_id":1,"qty":0,"weight":0,"avg":0,"trn_id":1,"remarks":"","shift":{"id":1,"status":"Active","name":""},"employee":{"id":1,"code":"","status":"Active","name":"","design_id":1},"item":{"id":0,"status":"Active","type":"","name":"","group_id":0,"unit_id":0,"brand_id":0,"quality_id":0,"color_id":0,"weight":0,"min_qty":0,"max_qty":0,"group":{"id":1,"name":"","type":0,"status":"Active","hsn":"","nature":0,"unit_id":1,"gstrate":0,"itcrate":0,"unit":{"id":1,"type":0,"name":"Nos","status":"Active","digits":0}},"unit":{"id":3,"type":0,"name":"Mtr","status":"Active","digits":2},"quality":{"id":0,"type":0,"name":"0","status":"Active","weight":0,"colorcharts":[]},"color":{"id":0,"type":0,"name":"","status":"Active"},"brand":{"id":1,"type":1,"name":"Primary","status":"Active"}},"quality":{"id":0,"type":0,"name":"","status":"Active","weight":0},"color":{"id":0,"type":0,"name":"Green","status":"Active","weight":0}}')
    }
  },
  filters: {
    weightFormat (val) {
      return val.toFixed(3)
    },
    numberFormat (val) {
      return val.toFixed(2)
    }
  },
  methods: {
    removeRow () {
      this.$emit('onRemoveRow', Math.abs(this.row.idx))
    },
    loadRoll (no) {
      const self = this;
      let rollno = 0;
      try {
        try {
          rollno = parseInt(no)

          if (isNaN(rollno)) {
            rollno = 0
          }

        } catch (e) {
          rollno = 0
        }

        if (rollno < 1) {
          return
        }

        const requestOptions = {
          method: 'GET',
          mode: 'cors',
          headers: userService.authHeader()
        }

        $('#mycardrow').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        })

        self.$data.row.idx = self.myindex;
        //`${process.env.VUE_APP_ROOT_API}api/roll/rollno/${rollno}`
        fetch(`${process.env.VUE_APP_ROOT_API}api/roll/${rollno}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycardrow').unblock()
          if (resp.ok) {

            if (!_.isNull(resp.data)) {
              self.$data.row.idx = self.myindex;
              self.$data.row = resp.data;
              self.$data.row.quality = resp.data.quality;
              self.$data.row.color = resp.data.color;
              self.$data.row.qty = resp.data.qty;
              self.$data.row.weight = resp.data.weight;
              self.showmsg(1);
            }
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer: 3000 });
          }
        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer: 3000 });
        }).finally(function () {
          $('#mycardrow').unblock();
        });
      }catch (e) {
        alert(e);
      }
    },
    showmsg (colIndex) {
      const self = this

      try {

        switch (colIndex) {
          case 1:
            // this.row.fabric.id
            this.$emit('onrow_change', this.index, colIndex, this.row)
            break
          case 3:
            this.$emit('onrow_change', this.index, colIndex, parseFloat(this.row.qty))
            break
        }
      } catch (e) {
        alert(e)
      }
    },
    validateQualityColor (fab) {

      const self = this
      let result = false
      try {
        //self.row.quality
        //self.row.color

        // alert("Quality : " + self.$props.parentvoucher.quality.id );
        // alert("Color : " + self.$props.parentvoucher.color.id );
        // alert(self.$props.parentvoucher.quality.id + " = " + fab.quality.id);
        /*
        if(self.$props.parentvoucher.quality.id !== fab.quality.id){
          alert("Invalid Quality");
          self.$refs.el_fabcode.focus();
        }else if(self.$props.parentvoucher.color.id !== fab.color.id){
          alert("Invalid Color");
          self.$refs.el_fabcode.focus();
        }else if(self.$props.parentvoucher.quality.id === fab.quality.id && self.$props.parentvoucher.color.id === fab.color.id){
          result = true;
        }
         */

        result = true

      } catch (e) {
        alert(e)
      }

      return result
    },
  }
}
</script>

<style scoped>
input:focus {
  background: #feff00;

}

textarea:focus {
  background: #feff00;

}

select:focus {
  background: #feff00;
}

button:focus {
  background: #feff00;
  color: #0a0a0a !important;
}
</style>
