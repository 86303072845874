<template>



  <FocusTrap>
    <div id="mycard" class="card">
      <div class="page-header page-header-light">

        <div class="breadcrumb-line breadcrumb-line-light header-elements-lg-inline">
        <div class="d-flex">
          <div class="breadcrumb">
            <a href="/" class="breadcrumb-item" tabindex="-1"><i class="icon-home2 mr-2"></i> Home</a>
            <a href="#" class="breadcrumb-item" tabindex="-1">Packing</a>
            <span class="breadcrumb-item active" tabindex="-1">New Bale</span>
          </div>
          <a href="#" class="header-elements-toggle text-body d-lg-none" tabindex="-1"><i class="icon-more"></i></a>
        </div>

        <WindowTitleBar title="Bale Creation" @loadData="loadData" @close_window="closeThis" >
          {{setTitle()}}
        </WindowTitleBar>
      </div>

    </div>
      <div class="card-body">

          <div class="row">

            <div class="col-md-2">
              <div class="form-group form-group-material ">
                <label class="control-label text-semibold">Shift</label>
                <select id="cmbshift" ref="cmbshift" class="form-control" autofocus="" v-if="voucher" v-model="voucher.shift_id">
                  <option value="2">Day</option>
                  <option value="3">Night</option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Order</label>
                <select class="form-control" required="" v-model="voucher.order_id" @change="saleorder_change" >
                  <option value="1" selected>Stock Bale</option>
                  <option v-for="saleorder in saleorders" v-bind:value="saleorder.id">
                    {{ saleorder.doc_no }} - {{ saleorder.buyer.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Item Type</label>
                <select class="form-control" required="" v-model="voucher.itemtype" @change="itemtype_change" >
                  <option value="1" selected>Knitted Rolls</option>
                  <option value="2" >Mosquito Nets</option>
                </select>
              </div>
            </div>


            <div class="col-md-4">

            </div>


            <div class="col-md-2">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Date</label>
                <input type="date" class="form-control" v-model="voucher.doc_date">
              </div>
            </div>
          </div>

          <div  v-if="voucher.itemtype == 1" class="row">
            <div class="col-md-12">
              <div class="table-responsive">

                      <table id="mytable" class="table table-no-bordered">
                        <thead style="background-color: lightgrey">
                        <tr>
                          <th style="width: 100px;">S.No</th>
                          <th style="width: 110px;">Roll No</th>
                          <th>#</th>
                          <th style="width: 100px;text-align: right;">Mtrs</th>
                          <th style="width: 100px;text-align: right;">Weight</th>
                          <th >Quality</th>
                          <th>Color</th>
                          <th style="width: 30px;">Action</th>
                        </tr>
                        </thead>
                        <tbody v-model="voucher" v-for="(fab,index) in voucher.list">
                          <BaleDetailRow :myindex="index" :parentvoucher="voucher"  :myrow="fab" v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow"></BaleDetailRow>
                        </tbody>
                        <tfoot>
                        <tr>
                          <td>
                            <button id="btnAddRow" type="button"
                                    class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple"
                                    @click="addRow">
                              <i class="icon-plus3"></i>
                            </button>
                          </td>
                          <td></td>
                          <td></td>
                          <td class="text-right font-weight-semibold" style="width: 100px;padding-right: 2px;">{{ totalmts | numberFormat }}</td>
                          <td class="text-right font-weight-semibold" style="width: 100px;padding-right: 5px;">{{ totalwgt | weightFormat }}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>
            </div>
          </div>

          <div  v-if="voucher.itemtype == 2" class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-no-bordered">
                  <thead style="background-color: lightgrey">
                  <tr>
                    <th style="width: 100px;" >S.No</th>
                    <th>Quality</th>
                    <th>Color</th>
                    <th style="width: 120px;">Size</th>
                    <th style="text-align: right;width: 120px;">Pcs</th>
                    <th style="text-align: right;width: 120px;">Weight</th>
                    <th style="width: 30px;">Action</th>
                  </tr>
                  </thead>
                  <tbody v-for="(detail,index) in voucher.list" >
                    <BaleMosquitoRow :myindex="index" :myrow="detail" :qualities = qualities :colors = colors v-on:onrow_change="onrow_change" v-on:onRemoveRow="removeRow" />
                  </tbody>
                  <tfoot>
                  <tr>
                    <td>
                      <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                        <i class="icon-plus3"></i>
                      </button>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td class="text-right font-weight-semibold" style="width: 100px;padding-right: 2px;">{{ totalmts | numberFormat }}</td>
                    <td class="text-right font-weight-semibold" style="width: 100px;padding-right: 5px;">{{ totalwgt | weightFormat }}</td>
                    <td></td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group form-group-material">
                <label class="control-label text-semibold">Narration</label>
                <textarea id="txtremarks" class="form-control" placeholder="Narration" v-if="voucher" v-model="voucher.remarks"> </textarea>
              </div>
            </div>


            <div class="col-md-6">

            </div>

            <div class="col-md-3 text-right">
              <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="saveVoucher">Save
              </button>
              <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear">Clear</button>
            </div>
          </div>
      </div>
    </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import { userService } from '@/store/auth-header.js'
import { store } from '@/store/store.js'
// import 'vue-select/dist/vue-select.css'
import BaleDetailRow from './BaleDetailRow'
import BaleMosquitoRow from './BaleMosquitoRow'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'BaleForm',
  store,
  components: {
    WindowTitleBar,
    BaleDetailRow,
    BaleMosquitoRow,
  },
  props: {
    myvoucher: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}')
    }
  },
  beforeMount () {
    const self = this;

    self.$data.voucher = this.myvoucher;
    self.$data.totalmts = 0.0;
    self.$data.totalwgt = 0.0;
    self.$data.voucher.list.forEach((row) => {
      self.$data.totalmts += parseFloat(row.qty);
      self.$data.totalwgt += parseFloat(row.weight);
    });

  },
  data () {
    return {
      totalwgt: 0,
      totalmts: 0,
      viewer: {},
      emps: [],
      colors: [],
      qualities: [],
      saleorders : [],
      voucher: JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}'),
      salorder: JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","buyer_id":0,"rolls":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}'),
      itemrates : new Map(),

    }
  },
  created () {

  },
  mounted () {
    const self = this;

    self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');

    if (self.$data.voucher.doc_date == '0001-01-01' || self.$data.voucher.doc_date == '2000-01-01') {
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
    }

    self.loadQualities();
    self.loadColors();
    self.loadActiveSaleOrders();

    setTimeout(()=>{
      $('#cmbshift').focus();
    },500);

  },
  filters:{
    weightFormat (val){
      return val.toFixed(3);
    },
    numberFormat (val){
      return val.toFixed(2);
    }
  },
  methods: {
    indianFormat (val) {
      return parseFloat(val).toFixed(2);
    },
    setTitle() {
      // return this.voucher.id == 0 ? "Bale Creation" : "Doc No: " + this.voucher.doc_no + "  Bale Updation";
      return this.voucher.id == 0 ? "" : "Doc No: " + this.voucher.bale_no + "  Bale Updation   ";
    },
    closeThis() {
      this.$emit('bale_window_closed');
      if(this.voucher.id > 0){
        this.voucher = JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
      }else{
        this.$router.push("/");
      }
    },
    addRow () {
      const self = this;
      try {
        if(self.$data.voucher.itemtype == 1) {
          let receipt = {
            "idx": 0,
            "id": 0,
            "status": "Active",
            "finyear": 0,
            "cmp_id": 1,
            "shift": { "id": 0, "type": 0, "name": "" },
            "doc_date": "2000-01-01",
            "roll_no": 0,
            "emp": { "id": 0, "status": 0, "emp_code": "", "name": "" },
            "process": { "code": 350, "name": "", "nature": 0 },
            "quality": { "id": 0, "name": "" },
            "color": { "id": 0, "status": 0, "name": "" },
            "qty": 0,
            "weight": 0,
            "avg": 0,
            "fabrics": [],
            "remarks": ""
          };
          self.$data.voucher.list.push(receipt);

          setTimeout(function () {
            $('tbody>tr').last().find('td:eq(1) input').focus()
          }, 99);
        }else if(self.$data.voucher.itemtype == 2) {
          let receipt = {"idx":0,"id":0,"qua_id":1,"col_id":1,"pcs":0,"qty":0,"weight":0,"quality":{"id":1,"name":""},"color":{"id":1,"name":""},"size":""};
          self.$data.voucher.list.push(receipt);

          setTimeout(function () {
            $('tbody>tr').last().find('td:eq(1) select').focus()
          }, 99);
        }


      } catch (e) {
        alert(e)
      }
    },
    onrow_change (rowIndex, colIndex, data) {
      const self = this;

      try {


        self.$data.voucher.list[rowIndex] = data;
        self.$data.totalmts = 0.0
        self.$data.totalwgt = 0.0;

          self.$data.voucher.list.forEach((row) => {
            self.$data.totalmts += parseFloat(row.qty);
            self.$data.totalwgt += parseFloat(row.weight);
          });

      } catch (e) {
        alert(e)
      }

    },
    removeRow (index) {
      const self = this;

      if (index > -1) {
        self.$data.voucher.list.splice(index, 1);
      }
    },
    clear () {
      const self = this;

      self.$data.voucher = JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","itemtype":1,"buyer_id":0,"order_id":0,"shift_id":0,"rolls":0,"qty":0,"weight":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
      self.$data.voucher.doc_date = moment().format('YYYY-MM-DD');
      self.$data.voucher.id = 0;
      self.$data.voucher.remarks = '';
      self.$data.totalmts = 0.0;
      self.$data.totalwgt = 0.0;
      $('#cmbshift').focus();
    },
    loadQualities () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities/after/1`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.qualities =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadColors () {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };


      $('#mycard').block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.$data.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colors`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)){
            self.$data.colors =  resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $('#mycard').unblock();
      });
    },
    loadActiveSaleOrders(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }


      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/saleorders/state/Active/0`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {

          if (_.isArray(resp.data)) {
            self.saleorders = resp.data;
          }

        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
        }

      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
      }).finally(function () {
        $('#mycard').unblock()
      });
    },
    itemtype_change(){
      const self = this;
    },
    saleorder_change(){
      const self = this;

      self.salorder = JSON.parse('{"id":0,"status":"Active","cmp_id":0,"type":0,"finyear":0,"doc_no":0,"doc_date":"2000-01-01","ref_no":12,"ref_date":"2000-01-01","buyer_id":0,"rolls":0,"remarks":"","buyer":{"id":0,"status":"Active","type":1,"name":"","street":"","city":"","pin":"","state_cd":33,"open_bal":0},"list":[]}');
      self.saleorders.forEach(function (order) {
        if(self.voucher.order_id == order.id) {
          self.salorder = order;
        }
      });


    },
    get_quality_rate(quality_id){
      const self = this;
      let result = 0;
      self.salorder.list.forEach(function (line) {
        if( line.quality_id == quality_id ) {
          result = line.rate;
        }
      });
      return result;
    },
    saveVoucher () {
      const self = this;

      try {

        $('#mycard').block({
          msg: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        let valid_data = true;

        self.$data.voucher.finyear = store.state.user.finyear;

        let myvoucher = JSON.parse(JSON.stringify(self.$data.voucher))

        myvoucher.finyear = self.$store.state.user.finyear;
        myvoucher.status = "Active";
        myvoucher.cmp_id = self.$store.state.user.company.id;
        myvoucher.doc_date = moment(self.$data.voucher.doc_date).format('YYYY-MM-DD');
        myvoucher.bale_date = moment(self.$data.voucher.bale_date).format('YYYY-MM-DD');

        myvoucher.itemtype = parseInt(self.$data.voucher.itemtype);
        myvoucher.shift_id = parseInt(myvoucher.shift_id);
        myvoucher.order_id = parseInt(myvoucher.order_id);
        myvoucher.pcs = myvoucher.list.length;
        myvoucher.rolls = myvoucher.list.length;
        myvoucher.qty = parseFloat(self.$data.totalmts);
        myvoucher.weight = parseFloat(self.$data.totalwgt);

        if(parseInt(self.$data.voucher.itemtype) == 2) {
          myvoucher.pcs = parseInt(self.$data.totalmts);
          myvoucher.rolls = parseInt(self.$data.totalmts);
          myvoucher.qty = parseFloat(self.$data.totalmts);
          myvoucher.weight = parseFloat(self.$data.totalwgt);
        }

        let arr = [];
        let idx = 1;
        let last_item_id =  parseInt(myvoucher.list[0].item_id);
        let last_quality_id =  parseInt(myvoucher.list[0].quality_id);

        myvoucher.list.forEach((row) => {
          row.idx = idx;
          row.id = parseInt(row.id);
          row.item_id = parseInt(row.item_id);
          row.quality_id = parseInt(row.quality_id);
          row.color_id = parseInt(row.color_id);
          row.weight = parseFloat(row.weight);
          row.qty = parseFloat(row.qty);
          arr.push(row.id);
          idx++;

          if(last_quality_id != row.quality_id){
            valid_data = false;
            alert('Unmatched Quality');
            return;
          }

          if(parseInt(self.$data.voucher.itemtype) == 2) {
            if(row.size.trim().length == 0){
              valid_data = false;
              alert('Invalid Size');
              return;
            }
          }


          if(parseInt(self.$data.voucher.itemtype) == 2) {
            myvoucher.weight += parseFloat(row.weight);
          }

        });

        // console.log(JSON.stringify(myvoucher));

        console.log(JSON.stringify(myvoucher));

        if(self.$data.voucher.itemtype < 1) {
          alert("Item Type not selected");
          valid_data = false;
          return;
        }else if (myvoucher.shift_id < 1) {
          valid_data = false;
          alert('Invalid Shift')
          return
        } else if (myvoucher.qty <= 0) {
          valid_data = false;
          alert('Invalid Roll Mtr')
          return
        } else if (myvoucher.weight <= 0 && self.$data.voucher.itemtype == 1) {
          valid_data = false;
          alert('Invalid Weight')
          return
        }


        const requestOptions = {
          method: (self.$data.voucher.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(myvoucher)
        }

        console.log(JSON.stringify(myvoucher));

        if(valid_data) {
          $('#mycard').block({
            msg: '<i class="icon-spinner2 spinner"></i>',
            overlayCSS: {
              backgroundColor: '#fff',
              opacity: 0.8,
              cursor: 'wait',
              'box-shadow': '0 0 0 1px #ddd'
            },
            css: {
              border: 0,
              padding: 0,
              backgroundColor: 'none'
            }
          });

          fetch(`${process.env.VUE_APP_ROOT_API}api/bale`, requestOptions).then(userService.handleResponse).then(function (resp) {
            if (resp.ok) {
              self.$data.voucher = resp.data;

              swal({
                type: 'success', text: "Bale No " + resp.data.bale_no, onClose: () => {
                  $('#cmbshift').focus();
                }, timer: 1500
              });

              self.clear();
              self.$emit('bale_saved', resp.data);
            } else {
              swal({
                title: 'Oops!', text: resp.msg, type: 'error', onClose: () => {
                  $('#cmbshift').focus()
                }, timer: 3000
              });
            }
          }).catch(function (err) {
            swal({
              title: 'Oh noes!', text: err.toString(), type: 'error', onClose: () => {
                $('#cmbshift').focus()
              }, timer: 3000
            })
          }).finally(function () {
            $('#mycard').unblock()
          });
        }

      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>


<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
